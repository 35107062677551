import React, { createContext, useContext, useState, useEffect } from 'react'
import axios from 'axios'

export interface Secrets {
  appInsightsSecretKey: string | null
}

export interface DynamicConfig {
  environment: 'unknown' | 'local' | 'development' | 'prod'
  apiUrl: string | null
  googleClientId: string | null
  stripePublicKey: string | null
  secrets: Secrets
}

interface ConfigContextType {
  config: DynamicConfig | null
  loading: boolean
  error: boolean
}

const ConfigContext = createContext<ConfigContextType>({
  config: null,
  loading: true,
  error: false,
})

export const useConfig = () => useContext(ConfigContext)

export const ConfigProvider: React.FC<{ children: React.ReactNode, fallback: React.ReactNode }> = ({ children, fallback }) => {
  const [ config, setConfig ] = useState<DynamicConfig | null>(null)
  const [ loading, setLoading ] = useState(true)
  const [ error, setError ] = useState(false)

  useEffect(() => {
    const configFileName = process.env.NODE_ENV === 'development'
      ? '/env/config.local.json'
      : '/config.json'

    axios.get(configFileName)
      .then((response) => {
        const loadedConfig = response.data
        setConfig({
          ...loadedConfig,
          secrets: { appInsightsSecretKey: null },
        })

        if (loadedConfig.apiUrl) {
          return axios.get(`${ loadedConfig.apiUrl }configuration/secrets`)
        } else {
          throw new Error('API URL is not valid')
        }
      })
      .then((secretResponse) => {
        setConfig((prevConfig) => {
          if (!prevConfig) return prevConfig
          return {
            ...prevConfig,
            secrets: { appInsightsSecretKey: secretResponse.data.appInsightsSecretKey },
          }
        })
        setLoading(false)
      })
      .catch((err) => {
        console.error('Error fetching configuration or secrets:', err)
        setError(true)
        setLoading(false)
      })

  }, [])

  if (loading) 
    return <div>{fallback}</div>

  return (
    <div>
      <ConfigContext.Provider value={ { config, loading, error } }>
        { children }
      </ConfigContext.Provider>
    </div>
  )
}