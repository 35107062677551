import React, { useState } from 'react'
import {
  AppBar,
  Avatar,
  Box,
  Container,
  IconButton,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText, SvgIconProps, Badge,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { useThemeMode } from '../utils/theme/themeContext'
import { useAuth } from '../auth/authContext'
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined'
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined'
import { Link, useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined'
import { useCartContext } from '../shared/cartContext'
import { useTranslation } from 'react-i18next'

const drawerWidth = 180
const settings = [ 'Logout', 'Language' ]
const menuItems: MenuItem[] = [
  {
    code: 'cart',
    icon: ShoppingCartOutlinedIcon,
    path: '/cart'
  }
]

type MenuItem = {
  code: string,
  icon: React.ElementType<SvgIconProps>,
  path: string
}

type TopBarWithDrawerProps = {
  showLoginHomeButton?: boolean
  showProfilePicture?: boolean
  showSearchBar?: boolean
  children: React.ReactNode
}

const TopBarWithDrawer: React.FC<TopBarWithDrawerProps> = ({ showLoginHomeButton = false, showProfilePicture = false, showSearchBar = false, ...props }) => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const themeMode = useThemeMode()
  const navigate = useNavigate()

  const { user, signOut } = useAuth()
  const { getItemsCount } = useCartContext()
  const [ anchorElUser, setAnchorElUser ] = useState<null | HTMLElement>(null)
  const [ drawerOpen, setDrawerOpen ] = useState(false)

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorElUser(event.currentTarget)

  const handleCloseUserMenu = (selectedSetting: string) => {
    if (selectedSetting === 'Logout')
      signOut()
    else if (selectedSetting === 'Language')
      i18n.changeLanguage(i18n.language === 'fr' ? 'en' : 'fr')

    setAnchorElUser(null)
  }

  const toggleDrawer = (open: boolean) => () =>
    setDrawerOpen(open)

  const drawerContent = (
    <Box sx={ { width: drawerWidth } } role="presentation" onClick={ toggleDrawer(false) } onKeyDown={ toggleDrawer(false) }>
      <List>
        { menuItems.map((item, index) => (
          <ListItem key={ item.code } disablePadding>
            <ListItemButton>
              <item.icon />
              <ListItemText primary={ item.code } />
            </ListItemButton>
          </ListItem>
        )) }
      </List>
    </Box>
  )

  return (
    <>
      <AppBar
        position="static"
        color="transparent"
        sx={ { height: '4rem', backgroundColor: theme.palette.primary.main, borderRadius: '0', paddingX: '0.3em' } }>
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={ { display: 'flex', justifyContent: 'space-between', alignItems: 'center' } }>
            <Box sx={ { display: 'flex', alignItems: 'center' } }>
              <IconButton sx={ { marginLeft:'-0.6em', color: 'white', display: { md: 'none' } } } size="large" aria-label="menu" onClick={ toggleDrawer(!drawerOpen) }>
                <MenuIcon />
              </IconButton>

              <Typography variant="h3" component={ Link } to="/" sx={ { color: 'white', fontWeight: '1000', margin: 0, textDecoration: 'none' } }>
                { t('general.appName') }
              </Typography>
              <IconButton sx={ { marginLeft: 0.5, marginTop: 1.5 } } onClick={ themeMode.toggleTheme }>
                { themeMode.darkMode
                  ? <LightModeOutlinedIcon sx={ { color: '#ededed' } } />
                  : <DarkModeOutlinedIcon sx={ { color: '#ededed' } } /> }
              </IconButton>
            </Box>

            <div style={ { display: 'flex', flexDirection: 'row' } }>
              <Box sx={ { marginRight: 1, display: { xs: 'none', md: 'flex' } } }>
                <IconButton size={ 'medium' } onClick={ _ => navigate('/cart') }>
                  <Badge color={ 'secondary' } badgeContent={ getItemsCount() }>
                    <ShoppingCartOutlinedIcon sx={ { color: '#ededed' } } fontSize={ 'medium' } />
                  </Badge>
                </IconButton>
              </Box>

              <Box sx={ { flexGrow: 0, marginLeft: 2 } }>
                <Box sx={ { display: 'flex', alignItems: 'center' } }>
                  <Typography sx={ { color: 'white', fontSize: '1rem', marginRight: '10px', fontWeight: '700' } }>
                    { user?.name }
                  </Typography>
                  <IconButton onClick={ handleOpenUserMenu } sx={ { p: 0 } }>
                    <Box
                      sx={ {
                        position: 'relative',
                        width: 54,
                        height: 54,
                        borderRadius: '50%',
                        background: `white`,
                        padding: '1px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                      } }>
                      <Avatar alt={ user?.name } src={ user?.profilePicture || '' } sx={ { width: '100%', height: '100%' } } />
                    </Box>
                  </IconButton>
                </Box>
                <Menu
                  sx={ { mt: '45px' } }
                  anchorEl={ anchorElUser }
                  anchorOrigin={ { vertical: 'top', horizontal: 'right' } }
                  keepMounted
                  transformOrigin={ { vertical: 'top', horizontal: 'right' } }
                  open={ Boolean(anchorElUser) }
                  onClose={ handleCloseUserMenu }>
                  { settings.map((setting) => (
                    <MenuItem
                      key={ setting }
                      onClick={ () => handleCloseUserMenu(setting) }
                      sx={ { marginBottom: 0 } }>
                      <Typography textAlign="center" sx={ { my: 0 } }>{ setting }</Typography>
                    </MenuItem>
                  )) }
                </Menu>
              </Box>
            </div>
          </Toolbar>
        </Container>
      </AppBar>

      <Drawer
        color="transparent"
        anchor="left"
        variant="persistent"
        open={ drawerOpen }
        sx={ {
          backgroundColor: 'transparent',
          boxShadow: '0 1px #75757533',
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            top: '4rem',
          },
        } }>
        { drawerContent }
      </Drawer>
      <Box sx={ { height: '100%', paddingLeft: drawerOpen ? `${ drawerWidth }px` : '0px', transition: 'padding-left 0.3s ease' } }>
        <Container maxWidth={ 'xl' }>
          { props.children }
        </Container>
      </Box>
    </>
  )
}

export default TopBarWithDrawer