import { Box, Button, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import AuthProviders from './AuthProviders'
import { useAuth } from './authContext'
import { useTheme } from '@mui/material/styles'
import CustomTextField from '../components/styled/CustomTextField'
import Footer from '../components/Footer'
import TopBar from '../components/TopBar'
import { useTranslation } from 'react-i18next'

export default function AuthenticationPage() {
  const { t } = useTranslation()
  const { isAuthenticated } = useAuth()
  const theme = useTheme()

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (isAuthenticated) {
    } else {
    }
  }

  return (
    <>
      <TopBar />
      <Container maxWidth="sm" sx={ { pt: 10, minHeight: 'calc(93vh - 5vh)' } }>
        <Box
          sx={ {
            p: 4,
            boxShadow: 3,
            borderRadius: 2,
            textAlign: 'center',
            backgroundColor: 'background.paper'
          } }>

          <Typography variant="h1" sx={ { fontSize: "4rem", mb: 4 } }>
            { isAuthenticated ? t('pages.authentication.welcomeBack') : t('pages.authentication.signUp') }
          </Typography>

          <AuthProviders />

          <Typography variant="body2" sx={ { mt: 2, mb: 2 } }>
            { t('pages.authentication.or') }
          </Typography>

          <form onSubmit={ handleFormSubmit }>
            <Grid container spacing={ 2 }>
              { !isAuthenticated && (
                <Grid item xs={ 12 }>
                  <CustomTextField
                    label={ t('pages.authentication.fullName') }
                    name="fullName"
                    fullWidth
                    required
                    disabled
                  />
                </Grid>
              ) }
              <Grid item xs={ 12 }>
                <CustomTextField
                  label={ t('pages.authentication.email') }
                  name="email"
                  type="email"
                  disabled
                  sx={ { color: theme.palette.text.primary } }
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={ 12 }>
                <CustomTextField
                  label={ t('pages.authentication.password') }
                  name="password"
                  type="password"
                  disabled
                  sx={ { color: theme.palette.text.primary } }
                  fullWidth
                  required
                />
              </Grid>
            </Grid>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled
              sx={ { mt: 3, mb: 2 } }>
              { isAuthenticated
                ? t('pages.authentication.loginButton')
                : t('pages.authentication.signupButton')
              }
            </Button>
          </form>

          <Typography variant="body2" sx={ { mt: 2 } }>
            { isAuthenticated ? t('pages.authentication.noAccount') : t('pages.authentication.alreadyHaveAccount') }
            <Button variant="text" sx={ { textTransform: 'none' } } disabled>
              { isAuthenticated
                ? t('pages.authentication.signupButton')
                : t('pages.authentication.loginButton')
              }
            </Button>
          </Typography>
        </Box>
      </Container>

      <Footer />
    </>
  )
}
