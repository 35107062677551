import React from 'react'
import { Container, Typography, Button, keyframes, IconButton } from '@mui/material'
import BuildCircleIcon from '@mui/icons-material/BuildCircleOutlined'
import TopBarAndFooter from '../components/TopBarAndFooter'
import { useTranslation } from 'react-i18next'

const bounce = keyframes`
	0%, 100% {
		transform: translateY(0)
	}
	50% {
		transform: translateY(-10px)
	}
`

const Fallback: React.FC = () => {
  const { t } = useTranslation()

  return (
    <TopBarAndFooter hideLoginButton>
      <Container
        maxWidth="xl"
        sx={ {
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          textAlign: 'center',
        } }
      >
        {/* <IconButton iconFontSize={ 120 } clickable={ false }> */}
          <BuildCircleIcon
            sx={ {
              mb: 3,
              color: '#fff',
              animation: `${ bounce } 2s infinite`,
            } }
            aria-label="Maintenance Icon"
          />
        {/* </IconButton> */}

        <Typography variant="h1">
          { t('pages.fallback.maintenance') } 🚀
        </Typography>

        <Typography variant="h3">
          { t('pages.fallback.success') }
        </Typography>

        <Typography variant="body1">
          { t('pages.fallback.adjustments') } <br />
          { t('pages.fallback.thanks') }
        </Typography>

        <Button
          sx={ {
            padding: '10px 30px',
            fontSize: '1.2rem',
            borderRadius: '30px',
            textTransform: 'none',
            mt: 4,
          } }
          href="/">
          { t('pages.fallback.returnToHome') }
        </Button>
      </Container>
    </TopBarAndFooter>
  )
}

export default Fallback
