import React, { createContext, ReactNode, useContext, useState } from 'react'
import { CartItem } from '../models/cartItem'
import { Guid } from 'guid-typescript'

type CartContextType = {
  cart: CartItem[]
  addToCart: (item: CartItem) => void
  removeFromCart: (itemId: Guid) => void
  getItemsCount: () => number
}

type CartContextProviderProps = {
  children: ReactNode
}

const CartContext = createContext<CartContextType | undefined>(undefined)

export function CartContextProvider({ children }: CartContextProviderProps) {
  const [ cart, setCart ] = useState<CartItem[]>([])

  function addToCart(item: CartItem) {
    if (!cart.some(x => x.id === item.id))
      setCart([ ...cart, item ])
  }

  function removeFromCart(itemId: Guid) {
    const updatedCart = cart.filter((item) => item.id !== itemId)
    setCart(updatedCart)
  }

  function getItemsCount() {
    return cart.length
  }

  return (
    <CartContext.Provider value={ { cart, addToCart, removeFromCart, getItemsCount } }>
      { children }
    </CartContext.Provider>
  )
}

export const useCartContext = () => {
  const context = useContext(CartContext)

  if (!context)
    throw new Error('useUserContext must be used within a UserContextProvider')

  return context
}