import { createTheme } from '@mui/material/styles'

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1767D2',
      light: '#4374ff',
      dark: '#0e39b2',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#e400ff',
      light: '#e933ff',
      dark: '#9f00b2',
      contrastText: '#FFF',
    },
    background: {
      default: '#fafcff',
      paper: '#fff',
    },
    text: {
      primary: '#555',
      secondary: '#fff',
    },
    error: {
      main: '#b90400',
    },
    warning: {
      main: '#ffa000',
    },
    info: {
      main: '#1976d2',
    },
    success: {
      main: '#39A83D',
    },
  },
  typography: {
    fontFamily: 'Nunito, Arial, sans-serif',
    h1: {
      fontSize: 'clamp(1.7rem, 3.5vw, 4rem)',
      fontWeight: 1000,
      lineHeight: '1',
      color: '#1767D2',
      marginBottom: '1rem',
    },
    h2: {
      fontSize: 'clamp(1.4rem, 2.8vw, 3rem)',
      fontWeight: 800,
      lineHeight: '1',
      color: '#1767D2',
      marginBottom: '1.5rem',
    },
    h3: {
      fontSize: 'clamp(1.2rem, 2.5vw, 2.5rem)',
      fontWeight: 700,
      color: '#1767D2',
      marginBottom: '2rem',
    },
    h4: {
      fontSize: 'clamp(1rem, 2.2vw, 2rem)',
      fontWeight: 500,
      color: '#1767D2',
      marginBottom: '1.5rem',
    },
    subtitle1: {
      fontSize: 'clamp(0.9rem, 1.8vw, 1.8rem)',
      color: '#555',
      marginBottom: '1rem',
      lineHeight: 1.2,
      fontWeight: 700,
    },
    subtitle2: {
      fontSize: 'clamp(0.8rem, 1.5vw, 1.3rem)',
      color: '#555',
      marginBottom: '1rem',
      lineHeight: 1.1,
      fontWeight: 500,
    },
    body1: {
      fontSize: 'clamp(0.8rem, 2vw, 1.2rem)',
      color: '#555',
      marginBottom: '2rem',
      lineHeight: 1.5,
    },
    body2: {
      fontSize: 'clamp(0.8rem, 1.8vw, 0.9rem)',
      color: '#555',
      marginBottom: '2rem',
      lineHeight: 1.4,
    },
    button: {
      fontSize: 'clamp(0.9rem, 1.5vw, 1.1rem)',
      fontWeight: 600,
      color: '#FFF',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            color: 'text.primary',
          },
          '& .MuiInputLabel-root': {
            color: 'text.primary'
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "20px"
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          marginBottom: 0
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#ECF5FF',
          boxShadow: '0px 4px 20px #BFD5F3',
          borderRadius: '20px'
        }
      }
    }
  },
})

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#1767D2',
      light: '#4374ff',
      dark: '#0e39b2',
    },
    secondary: {
      main: '#f48fb1',
      light: '#ff80ab',
      dark: '#d81b60',
      contrastText: '#FFF',
    },
    background: {
      default: '#121219',
      paper: '#1e1e28',
    },
    text: {
      primary: '#e0e0e0',
      secondary: '#bdbdbd',
    },
    error: {
      main: '#ef5350',
    },
    warning: {
      main: '#ffb74d',
    },
    info: {
      main: '#29b6f6',
    },
    success: {
      main: '#66bb6a',
    },
  },
  typography: {
    fontFamily: 'Nunito, Arial, sans-serif',
    h1: {
      fontSize: 'clamp(1.7rem, 3.5vw, 4rem)',
      fontWeight: 1000,
      lineHeight: '1',
      color: '#90caf9',
      marginBottom: '1rem',
    },
    h2: {
      fontSize: 'clamp(1.4rem, 2.8vw, 3rem)',
      fontWeight: 800,
      lineHeight: '1',
      color: '#90caf9',
      marginBottom: '1.5rem',
    },
    h3: {
      fontSize: 'clamp(1.2rem, 2.5vw, 2.5rem)',
      fontWeight: 700,
      color: '#90caf9',
      marginBottom: '2rem',
    },
    h4: {
      fontSize: 'clamp(1rem, 2.2vw, 2rem)',
      fontWeight: 500,
      color: '#90caf9',
      marginBottom: '1.5rem',
    },
    subtitle1: {
      fontSize: 'clamp(0.9rem, 1.8vw, 1.8rem)',
      color: '#e0e0e0',
      marginBottom: '1rem',
      lineHeight: 1.2,
      fontWeight: 700,
    },
    subtitle2: {
      fontSize: 'clamp(0.8rem, 1.5vw, 1.3rem)',
      color: '#bdbdbd',
      marginBottom: '1rem',
      lineHeight: 1.1,
      fontWeight: 500,
    },
    body1: {
      fontSize: 'clamp(0.8rem, 2vw, 1.2rem)',
      color: '#e0e0e0',
      marginBottom: '2rem',
      lineHeight: 1.5,
    },
    body2: {
      fontSize: 'clamp(0.8rem, 1.8vw, 0.9rem)',
      color: '#bdbdbd',
      marginBottom: '2rem',
      lineHeight: 1.4,
    },
    button: {
      fontSize: 'clamp(0.9rem, 1.5vw, 1.1rem)',
      fontWeight: 600,
      color: '#FFF',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            color: '#e0e0e0',
          },
          '& .MuiInputLabel-root': {
            color: '#bdbdbd',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "20px",
          backgroundColor: '#424242',
          '&:hover': {
            backgroundColor: '#616161',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          marginBottom: 0,
          color: '#e0e0e0',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#1e1e28',
          boxShadow: '0px 4px 20px #333',
          borderRadius: '20px',
        },
      },
    },
  },
})