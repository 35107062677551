import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
import { ConfigProvider } from './Infrastructure/ConfigProvider'
import SplashScreen from './pages/SplashScreen'
import { CssBaseline } from '@mui/material'
import { CustomThemeModeProvider } from './utils/theme/themeContext'
import { BrowserRouter as Router } from 'react-router-dom'
import './Infrastructure/traduction'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <React.StrictMode>
    <Router>
      <CustomThemeModeProvider>
        <CssBaseline />
        <ConfigProvider fallback={ <SplashScreen /> }>
          <App />
        </ConfigProvider>
      </CustomThemeModeProvider>
    </Router>
  </React.StrictMode>
)