import React from 'react'
import { TextField, TextFieldProps } from '@mui/material'

const CustomTextField: React.FC<TextFieldProps> = (props) => {
  return (
    <TextField
      {...props} 
      variant="outlined"
      sx={{
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'text.primary', 
          },
          '&:hover fieldset': {
            borderColor: 'text.primary', 
          },
          '&.Mui-focused fieldset': {
            borderColor: 'text.primary',
          },
        },
        '& .MuiInputLabel-root': {
          color: 'text.secondary', 
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: 'text.primary', 
        },
      }}
    />
  )
}

export default CustomTextField
