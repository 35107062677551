import React from 'react'
import { motion } from 'framer-motion'

const pageTransition = {
  initial: { opacity: 0, scale: 0.98 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.98 },
}

export const PageTransition: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      transition={ { duration: 0.2, ease: [ 0.4, 0, 0.2, 1 ] } }
      variants={ pageTransition }>
      { children }
    </motion.div>
  )
}