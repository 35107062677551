import React from 'react'
import { Box, Container, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const Footer: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Container maxWidth="xl" sx={ { flexGrow: 1, height: "4rem" } }>
      <Box
        maxWidth="xl"
        component="footer"
        position="static"
        sx={ {
          borderTop: '1px solid #2b2b2b',
          py: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        } }
      >
        <Typography variant="body2" sx={ { fontStyle: "italic", margin: 0 } }>
          { t('components.footer.copyright') }
        </Typography>

        <Typography variant="h4" sx={ { fontWeight: 1000, margin: 0 } }>
          { t('components.footer.appName') }
        </Typography>

        <div style={ { width: '1em' } }></div>
      </Box>
    </Container>
  )
}

export default Footer
