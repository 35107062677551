import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import translationFR from './locales/fr/translation.json'
import translationEN from './locales/en/translation.json'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'fr',
    fallbackLng: 'fr',
    resources: {
      fr: {
        translation: translationFR
      },
      en: {
        translation: translationEN
      },
    },
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: './locales/{{lng}}/translation.json',
    },
    react: {
      useSuspense: false,
    },
  })

export default i18n
