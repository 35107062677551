import React from 'react'
import { Container, Typography, keyframes, CircularProgress } from '@mui/material'
import TopBarAndFooter from '../components/TopBarAndFooter'
import { useTranslation } from 'react-i18next'

const pulseAnimation = keyframes`
	0% {
		opacity: 0.3
	}
	50% {
		opacity: 1
	}
	100% {
		opacity: 0.3
	}
`

const SplashScreen: React.FC = () => {
  const { t } = useTranslation()

  return (
    <TopBarAndFooter hideLoginButton>
      <Container
        maxWidth="xl"
        sx={ {
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        } }
      >
        <Typography
          variant="h1"
          sx={ { textAlign: 'center', animation: `${ pulseAnimation } 1.5s infinite ease-in-out` } }
        >
          { t('general.loading') }
        </Typography>

        <CircularProgress/>
        
        {/* <GradientCircularProgress disableShrink thickness={ 5 } size={ 90 } /> */}
      </Container>
    </TopBarAndFooter>
  )
}

export default SplashScreen
