import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()

let appInsightsInstance: ApplicationInsights
export let reactPlugin: ReactPlugin

export const initAppInsights = (instrumentationKey?: string | null) => {
  
  if (!instrumentationKey) {
    console.error("AppInsights error, no secret key")
    return
  }
  
  console.log('App insight initialized')
  
  reactPlugin = new ReactPlugin()

  appInsightsInstance = new ApplicationInsights({
    config: {
      instrumentationKey,
      extensions: [ reactPlugin ],
      extensionConfig: {
        [ reactPlugin.identifier ]: { history }
      },
      disableFetchTracking: false,
      enableAutoRouteTracking: true,
    }
  })

  appInsightsInstance.loadAppInsights()

  appInsightsInstance.addTelemetryInitializer((envelope) => {
    if (envelope.tags) {
      envelope.tags[ 'ai.cloud.role' ] = 'Frontend-WebApp'
    }
  })
}

export { appInsightsInstance }
