import React from 'react'
import { Box, Card, CardActions, CardContent, CardHeader, CardProps, Collapse, IconButton, IconButtonProps, styled, Typography } from '@mui/material'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { UserTutorial } from '../models/dtos/userTutorial'
import useApi from '../utils/api'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import FavoriteIcon from '@mui/icons-material/Favorite'
import ShareIcon from '@mui/icons-material/Share'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

type UserTutorialCardCardProps = {
  userTutorial: UserTutorial
  onShowContent: (content: string) => void
} & CardProps

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean
}

export default function UserTutorialCard(props: UserTutorialCardCardProps) {
  const api = useApi()

  async function getTutorialContent() {
    const content = await api.get<string>(`product/tutorial/${ props.userTutorial.id }/content`)
    if (content.data)
      props.onShowContent(content.data)
  }

  return (
    <Card
      sx={ {
        display: 'block',
        width: '30vw',
        minHeight: '10vw',
        transitionDuration: '0.3s'
      } }>
      <CardHeader
        title={ props.userTutorial.title }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        } />
      <CardActions disableSpacing>
        <IconButton
          aria-label="show" color={ 'inherit' } onClick={ getTutorialContent }>
          < VisibilityOutlinedIcon />
        </IconButton>
      </CardActions>
    </Card>
  )
}