import React, { useState } from 'react'
import { alpha, IconButton, InputBase, Paper } from '@mui/material'
import SearchIcon from '@mui/icons-material/SearchOutlined'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

type SearchBarProps = {
  onChange: (e: string) => void
  onClear: () => void
}

export const SearchBar: React.FC<SearchBarProps> = (props) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [value, setValue] = useState<string>('')

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
    setValue(inputValue)
    if (!inputValue) {
      props.onClear()
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      props.onChange(value)
    }
  }

  const handleClear = () => {
    setValue('')
    props.onClear()
  }

  return (
    <Paper
      elevation={0}
      sx={{
        height: '2em',
        maxWidth: '18em',
        display: 'flex',
        alignItems: 'center',
        padding: '2px 10px',
        borderRadius: '24px',
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
          backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        border: `1px solid ${theme.palette.primary.main}`,
        boxShadow:'none',
        justifyContent: 'space-between',
      }}
    >
      <InputBase
        sx={{
          margin: 0,
          width: '90%',
          color: theme.palette.primary.main,
          '&::placeholder': {
            color: theme.palette.text.secondary,
          },
        }}
        value={value}
        placeholder={t('components.searchBar.placeholder')}
        inputProps={{ 'aria-label': t('components.searchBar.ariaLabel') }}
        onKeyDown={handleKeyDown}
        onChange={handleInputChange}
      />
      <div
        style={{
          width: '120px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          alignItems: 'center',
        }}
      >
        <IconButton size={'small'} onClick={() => props.onChange(value)}>
          <SearchIcon sx={{color: theme.palette.primary.main}} fontSize={'medium'} />
        </IconButton>
      </div>
    </Paper>
  )
}
