import React from 'react';
import { Dialog, DialogContent, DialogTitle, Typography, Box } from '@mui/material';
import MarkdownRenderer from './MarkdownRenderer';

interface ProductContentDialogProps {
  openDialog: boolean;
  handleCloseDialog: () => void;
  selectedTutorialContent: string;
}

const ProductContentDialog: React.FC<ProductContentDialogProps> = ({ openDialog, handleCloseDialog, selectedTutorialContent, }) => {
  const titleRegex = /<flag-title>([\s\S]*?)<\/flag-title>/;
  const introRegex = /<flag-introduction>([\s\S]*?)<\/flag-introduction>/;

  const matchTitle = selectedTutorialContent.match(titleRegex);
  const matchIntro = selectedTutorialContent.match(introRegex);

  const tutorialTitle = matchTitle ? matchTitle[ 1 ].replace(/[\*\*_]/g, '').trim() : 'Untitled Tutorial';
  const tutorialIntroduction = matchIntro ? matchIntro[ 1 ].trim() : '';

  const cleanedContent = selectedTutorialContent
    .replace(titleRegex, '')
    .replace(introRegex, '')
    .trim();

  return (
    <Dialog
      open={ openDialog }
      onClose={ handleCloseDialog }
      fullWidth
      maxWidth="lg"
      sx={ {
        '& .MuiDialog-paper': {
          width: { xs: '90vw' },
          height: { xs: '90vh' },
        },
      } }
    >
      <DialogTitle>{ tutorialTitle }</DialogTitle>
      <DialogContent sx={ { height: '100%', overflowY: 'auto' } }>
        { tutorialIntroduction && (
          <Box sx={ { mb: 4 } }>
            <Typography variant="body1" gutterBottom>
              { tutorialIntroduction }
            </Typography>
          </Box>
        ) }
        <MarkdownRenderer content={ cleanedContent } />
      </DialogContent>
    </Dialog>
  );
};

export default ProductContentDialog;