import React from 'react'
import { AppBar, Avatar, Box, Button, Container, IconButton, Toolbar, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { useThemeMode } from '../utils/theme/themeContext'
import { useAuth } from '../auth/authContext'
import { useTheme } from '@mui/material/styles'
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined'
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined'
import { useTranslation } from 'react-i18next'

interface TopBarProps {
  navigate?: (url: string) => void
  showLoginHomeButton?: boolean
  showProfilePicture?: boolean
  showSearchBar?: boolean
}

const TopBar: React.FC<TopBarProps> = ({ showLoginHomeButton = false, showProfilePicture = false, showSearchBar = false, ...props }) => {
  const { isAuthenticated, user } = useAuth()
  const themeMode = useThemeMode()
  const theme = useTheme()
  const { t } = useTranslation()

  const handleLoginClicked = () => {
    if (props.navigate && isAuthenticated)
      props.navigate('/home')
    else if (props.navigate && !isAuthenticated)
      props.navigate('/auth')
  }
  
  return (
    <AppBar
      position="static"
      color="transparent"
      sx={ { height: '4rem', backgroundColor: theme.palette.primary.main, borderRadius: '0', paddingX: '0.3em' } }>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={ { display: 'flex', justifyContent: 'space-between', alignItems: 'center' } }>
          <Box sx={ { display: 'flex', alignItems: 'center' } }>
            <Typography
              variant="h3"
              component={ Link }
              to="/"
              sx={ { color: 'white', fontWeight: '1000', margin: 0, textDecoration: 'none' } }>
              { t('components.topBar.appName') }
            </Typography>
            <IconButton sx={ { marginLeft: 0.5, marginTop: 1.5 } } onClick={ themeMode.toggleTheme }>
              { themeMode.darkMode
                ? <LightModeOutlinedIcon sx={ { color: '#ededed' } } />
                : <DarkModeOutlinedIcon sx={ { color: '#ededed' } } /> }
            </IconButton>
          </Box>

          { showLoginHomeButton && (
            <Button onClick={ handleLoginClicked }>
              { isAuthenticated ? t('components.topBar.goToHome') : t('components.topBar.login') }
            </Button>
          ) }

          { showProfilePicture && user && (
            <Box sx={ { display: 'flex', alignItems: 'center' } }>
              <Typography sx={ { fontSize: '1rem', marginRight: '10px', fontWeight: '700',  color: 'white' } }>
                { user.name }
              </Typography>
              <Box
                sx={ {
                  position: 'relative',
                  width: 54,
                  height: 54,
                  borderRadius: '50%',
                  background: 'white',
                  padding: '3px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
                } }>
                <Avatar alt={ user.name } src={ user.profilePicture || '' } sx={ { width: '100%', height: '100%' } } />
              </Box>
            </Box>
          ) }

        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default TopBar
