import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { Stepper, Step, StepLabel, Typography, Box, Button, Alert } from '@mui/material'
import { useTranslation } from 'react-i18next'

function MarkdownRenderer({ content }: { content: string }) {
  const { t } = useTranslation()
  const [ activeStep, setActiveStep ] = useState(0)

  const stepRegex = /<flag-step(\d+)>([\s\S]*?)<\/flag-step\1>/g
  const infoRegex = /<flag-info>([\s\S]*?)<\/flag-info>/g
  const warningRegex = /<flag-warning>([\s\S]*?)<\/flag-warning>/g

  const steps = Array.from(content.matchAll(stepRegex)).map((match) => {
    const stepContent = match[ 2 ]
    const infos = Array.from(stepContent.matchAll(infoRegex)).map((infoMatch) => infoMatch[ 1 ].trim())
    const warnings = Array.from(stepContent.matchAll(warningRegex)).map((warningMatch) => warningMatch[ 1 ].trim())
    const cleanedContent = stepContent
      .replace(infoRegex, '')
      .replace(warningRegex, '')
      .trim()

    return {
      stepNumber: Number(match[ 1 ]),
      title: cleanedContent.split('\n')[ 0 ]?.replace(/\*\*/g, '').trim(),
      content: cleanedContent.replace(cleanedContent.split('\n')[ 0 ] || '', '').trim(),
      infos,
      warnings,
    }
  })

  const handleNext = () => {
    setActiveStep((prevActiveStep) => Math.min(prevActiveStep + 1, steps.length - 1))
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0))
  }

  if (steps.length === 0) {
    return <Typography variant="body1">{ t('components.markdownRenderer.noStepsFound') }</Typography>
  }

  return (
    <Box sx={ { display: 'flex', flexDirection: 'column', height: '100%' } }>
      { steps.length > 0 && (
        <Stepper activeStep={ activeStep } alternativeLabel>
          { steps.map((step, index) => (
            <Step key={ index }>
              <StepLabel onClick={ () => setActiveStep(index) }>{ step.title }</StepLabel>
            </Step>
          )) }
        </Stepper>
      ) }

      <Box sx={ { flexGrow: 1, overflowY: 'auto', padding: 2, marginTop: 2 } }>
        <Typography variant="h5" gutterBottom>
          { steps[ activeStep ].title }
        </Typography>

        <ReactMarkdown remarkPlugins={ [ remarkGfm ] }>{ steps[ activeStep ].content }</ReactMarkdown>

        { steps[ activeStep ].infos.length > 0 && (
          <Box sx={ { mt: 2 } }>
            { steps[ activeStep ].infos.map((info, index) => (
              <Alert key={ index } variant='outlined' severity='info'>
                <ReactMarkdown remarkPlugins={ [ remarkGfm ] }>{ info }</ReactMarkdown>
              </Alert>
            )) }
          </Box>
        ) }

        { steps[ activeStep ].warnings.length > 0 && (
          <Box sx={ { mt: 2 } }>
            { steps[ activeStep ].warnings.map((warning, index) => (
              <Alert key={ index } variant='outlined' severity='warning'>
                <ReactMarkdown remarkPlugins={ [ remarkGfm ] }>{ warning }</ReactMarkdown>
              </Alert>
            )) }
          </Box>
        ) }
      </Box>

      <Box
        sx={ {
          display: 'flex',
          justifyContent: 'space-between',
          padding: 2,
          borderTop: '1px solid #e0e0e0',
          marginTop: 'auto',
        } }
      >
        <Button disabled={ activeStep === 0 } onClick={ handleBack }>
          { t('components.markdownRenderer.back') }
        </Button>
        <Button disabled={ activeStep === steps.length - 1 } onClick={ handleNext }>
          { t('components.markdownRenderer.next') }
        </Button>
      </Box>
    </Box>
  )
}

export default MarkdownRenderer