import React, { createContext, useContext, useState, useEffect } from 'react'
import useApi from '../utils/api'
import { Guid } from 'guid-typescript'
import { useNavigate } from 'react-router-dom'

type User = {
  id: Guid,
  email: string,
  name: string
  profilePicture: string | null
}

type AuthContextType = {
  user: User | null
  token: string | null
  signIn: (token: string) => void
  signOut: () => void
  isAuthenticated: boolean
  isLoading: boolean
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  token: null,
  isAuthenticated: false,
  isLoading: true,
  signIn: () => {
  },
  signOut: () => {
  },
})

export const useAuth = () => useContext(AuthContext)

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [ token, setToken ] = useState<string | null>(null)
  const [ user, setUser ] = useState<User | null>(null)
  const [ isLoading, setIsLoading ] = useState(true)

  const navigate = useNavigate()
  const api = useApi()

  const signIn = async (token: string) => {
    setToken(token)
    localStorage.setItem('auth_token', token)

    try {
      const response = await api.get<User>('referential/user/profile')
      setUser(response.data)
    } catch (error) {
      console.error('Error fetching user profile:', error)
    }
    setIsLoading(false)
  }

  const signOut = () => {
    navigate('/')
    
    setTimeout(() => {
      setToken(null)
      setUser(null)
      localStorage.removeItem('auth_token')
      setIsLoading(false)
    }, 500)
  }

  useEffect(() => {
    const storedToken = localStorage.getItem('auth_token')
    if (storedToken) {
      setToken(storedToken)
      signIn(storedToken)
    }
    setIsLoading(false)
  }, [])

  return (
    <AuthContext.Provider value={ { user, token, signIn, signOut, isAuthenticated: !!token, isLoading } }>
      { children }
    </AuthContext.Provider>
  )
}
