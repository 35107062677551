import React from 'react'
import Footer from '../components/Footer'
import { Box, Container } from '@mui/material'
import TopBar from './TopBar'
import { useNavigate } from 'react-router-dom'
import { useThemeMode } from '../utils/theme/themeContext'

interface TopBarAndFooterProps {
  children: React.ReactNode
  hideLoginButton?: boolean
  displayProfilePicture?: boolean
}

const TopBarAndFooter: React.FC<TopBarAndFooterProps> = ({ children, hideLoginButton, displayProfilePicture }) => {
  const navigate = useNavigate()
  const themeMode = useThemeMode()
 
  return (
    <>
      {
        hideLoginButton
          ? <TopBar />
          : displayProfilePicture
            ? <TopBar showProfilePicture />
            : <TopBar showLoginHomeButton navigate={navigate} />
      }

      <Container
        maxWidth="xl" sx={ {
        paddingX: themeMode.isSmScreenOrLower ? 0 : undefined,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      } }>

        <Container
          maxWidth={'xl'}
          sx={ {
            minHeight: 'calc(92vh - 5vh)',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          } }>
          { children }
        </Container>

        <Footer />
      </Container>
    </>
  )
}

export default TopBarAndFooter
